import React from 'react';
import Slider from './SliderBase';

// CONTENT // 
const recent = [
    {
        file: "images/art/senshi.png",
        alt: "a digital painting of Senshi from Dungeon Meshi",
        caption: 'Fanart sticker - "Senshi"'
    },
    {
        file: "images/art/commissions/forwin.png",
        alt: "A digital painting of a grey schnauzer with goat horns and red eyes, glaring nefariously into the camera with evil steeple hands",
        caption: 'Character commission - Stampede'
    },
    {
        file: "images/art/commissions/dahlia.png",
        alt: "a front-view reference sheet of a brown and purple hellhound with green horns and a green glyph on her chest. she has long brown hair with purple highlights",
        caption: 'Character design commission - "Dahlia"'
    },
    {
        file: "images/art/Crow.png",
        alt: "a digital painting of a crow with a knife in their mouth",
        caption: 'Sticker - "Crow"'
    },
    {
        file: "images/art/commissions/dahlia icon.png",
        alt: "a side view painting of a purple and brown hellhound with green horns",
        caption: 'Character icon - "Dahlia"'
    },
    {
        file: "images/art/HeartContainer.png",
        alt: "a digital painting of finger gloved hands holding a heart container from Zelda",
        caption: 'Fanart sticker - "Heart Container"'
    },
    {
        file: "images/art/commissions/charliesona.png",
        alt: "a reference sheet of the front and back views of a brown anthropomorphic rabbit with long green curly hair",
        caption: 'Character design commission - "Charlie"'
    },
    {
        file: "images/art/lionguy.png",
        alt: "a yellow lion with a purple mane and beard, smiling while wearing a studded leather jacket",
        caption: 'Original character piece - "Trevor"'
    },
    {
        file: "images/art/godzilla.png",
        alt: "a digital painting of rubber suit godzilla breathing blue fire that says 'bwaaa'",
        caption: 'Fanart sticker - "Godzilla"'
    },
    {
        file: "images/art/anyunsalid.png", 
        alt: "a purple tiefling with 4 horns and pierced ears smiles, revealing a gold fang. Next to him are the words 'Anyun Salid' and an onion.",
        caption: 'Original character piece - "Anyun Salid"'
    },
    {
        file: "images/art/briarsona badge no-text.png",
        alt: "an anthropomorphic red panda in maroon and light blue with rainbow hair, smiling and holding a cup of coffee",
        caption: 'Original character piece - "Briar"'
    },
    {
        file: "images/art/hug.png",
        alt: "an anthropomorphic red panda and kitsune hugging. the red panda is maroon and light blue with rainbow hair, and the kitsune is in shades of red",
        caption: 'Original character piece with my partner - "Hugs"'
    },
    {
        file: "images/art/BrookeHeart-online.png",
        alt: "a digital painting of Brooke, a water elemental, making a heart with her hands",
        caption: 'Original character sticker - "Brooke"'
    },
    {
        file: "images/art/cool dogz.png",
        alt: "a digital drawing of two anthropomorphic dogs in different shades of white, pink, and blue. they are leaning against each other and wearing sunglasses. in front of them are the words 'cool dogz' with a z.",
        caption: 'Original character piece with a friend - "Cool Dogz"'
    },
    {
        file: "images/art/cigarette.jpg",
        alt: "a digital drawing of two monstrous humanoids - one fish-like, the other blue and fuzzy with horns - lighting each other's cigarettes.",
        caption: 'Original character piece - "Cigarette"'
    }
];

const RecentSlider = (props) => {
    return <Slider data={recent} sliderName="recent" />
}

export default RecentSlider;
