import React from 'react';
import Slider from './SliderBase';

// CONTENT // 
const singlepage = [
    // add other ones here 
    {
        file: "images/websiteExamples/Survey.png",
        alt: "A screenshot of a survey form.",
        caption: "Certification Project - Survey Form. Form will not submit, this is a frontend design example.",
        link: "https://codepen.io/curiositybard/full/OJzPOox"
    },
    {
        file: "images/websiteExamples/Drumpad.png",
        alt: "A screenshot of a drumpad app.",
        caption: "Certification Project - Drumpad in JavaScript & React",
        link: "https://codepen.io/curiositybard/full/OJzrwXE"
    },
    {
        file: "images/websiteExamples/MarkdownEdit.png",
        alt: "A screenshot of a markdown previewer app.",
        caption: "Certification Project - Markdown Previewer in JavaScript & React",
        link: "https://codepen.io/curiositybard/full/XWVBMEr"
    },
    {
        file: "images/websiteExamples/Documentation.png",
        alt: "A screenshot from a javascript documentation page",
        caption: "Certification Project - Technical Documentation Page",
        link: "https://codepen.io/curiositybard/full/PoEqrrp"
    },
    {
        file: "images/websiteExamples/DocumentationDark.png",
        alt: "A screenshot from a javascript documentation page",
        caption: "Certification Project - Technical Documentation Page",
        link: "https://codepen.io/curiositybard/full/PoEqrrp"
    },
    {
        file: "images/websiteExamples/Tribute.png",
        alt: "A screenshot from a tribute page for Amelia Earhart",
        caption: "Certification Project - Tribute Page",
        link: "https://codepen.io/curiositybard/full/BaJaRYz"
    },
    {
        file: "images/websiteExamples/Calculator.png",
        alt: "A screenshot from a calculator app",
        caption: "Certification Project - Calculator in JavaScript & React",
        link: "https://codepen.io/curiositybard/full/vYpPMGp"
    }
];

const SinglePageSitesSlider = (props) => {
    return <Slider data={singlepage} sliderName="single-page" />
}

export default SinglePageSitesSlider;