import React from 'react';
import '../style.css';

export const ArtSocials = () => {
    return (
        <div className="social" id="art-socials">
            <a className="social-link" id="tumblr-link" href="https://curiositybard.tumblr.com" target="_blank" rel="noreferrer"><figure>
                <img src="images/Icons/tumblr.png" alt="Tumblr Icon" />
                <figcaption>Tumblr</figcaption>
            </figure></a>
            <a className="social-link" id="instagram-link" href="https://www.instagram.com/curiosity.bard/" target="_blank" rel="noreferrer" ><figure>
                <img src="images/Icons/instagram.png" alt="Instagram Icon"/>
                <figcaption>Instagram</figcaption>
            </figure></a>
            {/*<a className="social-link" id="etsy-link" href="https://www.etsy.com/shop/CuriosityBard" target="_blank" rel="noreferrer" ><figure>
                <img src="images/Icons/etsy.png" alt="Etsy Icon"/>
                <figcaption>Etsy</figcaption>
            </figure></a>
            <a className="social-link" id="toyhouse-link" href="https://toyhou.se/curiositybard" target="_blank" rel="noreferrer" ><figure>
                <img src="images/Icons/toyhouse.png" alt="Etsy Icon"/>
                <figcaption>Toyhou.se</figcaption>
            </figure></a>*/}
        </div>
    );
}

export const DevSocials = () => {
    return (
        <>
            <div className="social" id="dev-socials">
                <a className="social-link" id="github-link" href="https://github.com/CuriosityBard" target="_blank" rel="noreferrer" ><figure>
                    <img src="images/Icons/GitHub.png" alt="GitHub Icon"/>
                    <figcaption>Github</figcaption>
                </figure></a>
                <a className="social-link" id="fcc-link" href="https://www.freecodecamp.org/curiositybard" target="_blank" rel="noreferrer" ><figure>
                    <img src="images/Icons/fcc.png" alt="FreeCodeCamp Icon"/>
                    <figcaption>FCC</figcaption>
                </figure></a>
            </div>
        </>
    );
}