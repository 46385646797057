import React from 'react';
import './style.css';
import { NavLink, Routes, Route } from 'react-router-dom';
import Home from './pages/home.js';
import Portfolio from './pages/portfolio.js';
import Webdev from './pages/webdev.js';
import Commissions from './pages/commissions.js';
import Inktober from './pages/inktober23';
import { Preload } from './reusable/preloadall';

const App = () => (
    <div className='app'>
        <header className="page-head"><h1 className="page-title">Art by Briar</h1></header>
        <Navigation />
        <Main />
        <Preload />
    </div>
);

const Navigation = () => (
    <nav className="site-nav">
        {/* id=you-are-here for current page */}
        <NavLink exact className="nav-link" to="/">Home</NavLink>
        <NavLink exact className="nav-link" to="/portfolio">Art Portfolio</NavLink>
        <NavLink exact className="nav-link" to="/commissions">Commissions</NavLink>
    </nav>
)

const Main = () => (
    <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/portfolio' element={<Portfolio />}></Route>
        <Route path='/webdev' element={<Webdev />}></Route>
        <Route path='/commissions' element={<Commissions />}></Route>
        <Route path='/inktober' element={<Inktober />}></Route>
    </Routes>
);

export default App;