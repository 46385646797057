import React from 'react';
import '../style.css'

import { BoxTitle } from '../reusable/titles.js';
import DigitalSlider from '../sliders/DigitalSlider.js'
import PhysicalSlider from '../sliders/PhysicalSlider.js';

export default class Portfolio extends React.Component {
    render() {
        return (
            <main id="portfolio-page">
                <section className="boxed box-section hold-slider" id="digital">
                <BoxTitle text="Digital Art" />
                    <div className="box-body">
                        <div id="slider-digital">
                            <DigitalSlider />
                        </div>
                    </div>
                </section>
                <section className="boxed box-section hold-slider" id="mixed-media">
                    <BoxTitle text="Physical Art" />
                    <div className="box-body">
                        <div id="slider-mixedmedia">
                            <PhysicalSlider />
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}