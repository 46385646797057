import React from 'react';
import Slider from './SliderBase';

// CONTENT // 
const digital = [
    // add other ones here 
    {
        file: "images/art/senshi.png",
        alt: "a digital painting of Senshi from Dungeon Meshi",
        caption: 'Fanart sticker - "Senshi"'
    },
    {
        file: "images/art/commissions/forwin.png",
        alt: "A digital painting of a grey schnauzer with goat horns and red eyes, glaring nefariously into the camera with evil steeple hands",
        caption: 'Character commission - Stampede'
    },
    {
        file: "images/art/commissions/dahlia.png",
        alt: "a front-view reference sheet of a brown and purple hellhound with green horns and a green glyph on her chest. she has long brown hair with purple highlights",
        caption: 'Character design commission - "Dahlia"'
    },
    {
        file: "images/art/Crow.png",
        alt: "a digital painting of a crow with a knife in their mouth",
        caption: 'Sticker - "Crow"'
    },
    {
        file: "images/art/commissions/dahlia icon.png",
        alt: "a side view painting of a purple and brown hellhound with green horns",
        caption: 'Character icon - "Dahlia"'
    },
    {
        file: "images/art/HeartContainer.png",
        alt: "a digital painting of finger gloved hands holding a heart container from Zelda",
        caption: 'Fanart sticker - "Heart Container"'
    },
    {
        file: "images/art/commissions/charliesona.png",
        alt: "a reference sheet of the front and back views of a brown anthropomorphic rabbit with long green curly hair",
        caption: 'Character design commission - "Charlie"'
    },
    {
        file: "images/art/lionguy.png",
        alt: "a yellow lion with a purple mane and beard, smiling while wearing a studded leather jacket",
        caption: 'Original character piece - "Trevor"'
    },
    {
        file: "images/art/godzilla.png",
        alt: "a digital painting of rubber suit godzilla breathing blue fire that says 'bwaaa'",
        caption: 'Fanart sticker - "Godzilla"'
    },
    {
        file: "images/art/anyunsalid.png", 
        alt: "a purple tiefling with 4 horns and pierced ears smiles, revealing a gold fang. Next to him are the words 'Anyun Salid' and an onion.",
        caption: 'Original character piece - "Anyun Salid"'
    },
    {
        file: "images/art/briarsona badge no-text.png",
        alt: "an anthropomorphic red panda in maroon and light blue with rainbow hair, smiling and holding a cup of coffee",
        caption: 'Original character piece - "Briar"'
    },
    {
        file: "images/art/hug.png",
        alt: "an anthropomorphic red panda and kitsune hugging. the red panda is maroon and light blue with rainbow hair, and the kitsune is in shades of red",
        caption: 'Original character piece with my partner - "Hugs"'
    },
    {
        file: "images/art/BrookeHeart-online.png",
        alt: "a digital painting of Brooke, a water elemental, making a heart with her hands",
        caption: 'Original character sticker - "Brooke"'
    },
    {
        file: "images/art/cool dogz.png",
        alt: "a digital drawing of two anthropomorphic dogs in different shades of white, pink, and blue. they are leaning against each other and wearing sunglasses. in front of them are the words 'cool dogz' with a z.",
        caption: 'Original character piece with a friend - "Cool Dogz"'
    },
    {
        file: "images/art/cigarette.jpg",
        alt: "a digital drawing of two monstrous humanoids - one fish-like, the other blue and fuzzy with horns - lighting each other's cigarettes.",
        caption: 'Original character piece - "Cigarette"'
    },
    {
        file: "images/art/titania sona.png",
        alt: "a digital character reference sheet of a red and cream anthropomorphic marbled fox labeled Titania",
        caption: 'Character reference sheet - "Titania"'
    },
    {
        file: "images/art/soniefinal.png",
        alt: "a digital drawing of a maroon red panda with rainbow hair that goes down their back and tail, wearing a crop top and making a flamboyant pose in front of large, colorful bubbles",
        caption: 'Original character piece - "Flamboyance"'
    },
    {
        file: "images/art/wolf.png",
        alt: "a magic the gathering token for a 2/2 wolf, which portrays a gray wolf stepping into frame",
        caption: 'MTG token - 2/2 Wolf'
    },
    {
        file: "images/art/squirrel.png",
        alt: "a magic the gathering token for a 1/1 squirrel, which portrays a brown and red squirrel sitting on a tree branch",
        caption: 'MTG token - 1/1 Squirrel'
    },
    {
        file: "images/art/soldier.png",
        alt: "a magic the gathering token for a 1/1 soldier, which portrays a red-haired, green-eyed soldier in shining armor, holding a halberd.",
        caption: 'MTG token - 1/1 Soldier'
    },
    {
        file: "images/art/fanart/undertale 8th anniversary.png",
        alt: "a digital drawing of Napstablook, Mettaton, Papyrus, Sans, Frisk, Flowey, Annoying Dog, Toriel, Asgore, Alphys, and Undyne from Undertale.",
        caption: 'Fanart - "Happy Birthday Undertale"'
    },
    {
        file: "images/art/commissions/barkeep.png",
        alt: "a digital drawing of a heavy-set barkeeper with freckles and brown hair, cleaning a tankard as he smiles softly.",
        caption: 'Character commission - "Mülpith"'
    },
    {
        file: "images/art/commissions/patron.png", 
        alt: "a digital drawing of a red-haired orc with green skin, his head in his hands as he looks, lovestruck, towards the viewer. Behind him are three blurred figures.",
        caption: 'Character commission - "Sir Anderson"'
    },
    {
        file: "images/art/anyun.PNG",
        alt: "a digital drawing of a purple man with four horns nestled in curly maroon hair. he is leaning, arms crossed, on the wall of a ship. The background shows a mast and barrel behind him.",
        caption: 'Original Character - "Anyun"'
    },
    /*{
        file: "images/art/alec.png",
        alt: "a digital cartoon of a smiling Black man with brown eyes and short hair. he is wearing a sleeveless black hoodie with a skintone undershirt, and his name, Alec, is written in the background.",
        caption: 'Original Character - "Alec"'
    },
    {
        file: "images/art/bardref.png",
        alt: "a digital reference sheet for an anthropomorphic sun bear character named Bard. Three views are shown, a full body front view, a full body side view, and a bust view with his long tongue out. the bear is dark brown with lavender markings and pink hair and beard. Some of the markings appear like roses.",
        caption: 'Original Character Reference Sheet - "Bard"'
    },
    {
        file: "images/art/bearold.png",
        alt: "a digital drawing of an anthropomorphic cat, sitting with their legs crossed and waving at the audience. they are white and purple, with pink hair. Their hair is longer around their shoulders, and their tail is very fluffy. They have a heart-shaped eyebrow piercing on the right side.",
        caption: 'Original Character - "Bearold"'
    },*/
    {
        file: "images/art/brooke.png",
        alt: "a digital drawing of the head of a water elemental with dark blue eyes. her name, Brooke, is emblazoned next to her head, and she wears a pink flower in what would be her hair, if it weren't made of water",
        caption: 'Original Character Badge - "Brooke"'
    },
    {
        file: "images/art/fanart/first-day-of-spring.jpg",
        alt: "a digital drawing of Snufkin and Moomin from the Moomins universe, smiling with their noses together.",
        caption: 'Fanart - "First Day of Spring"'
    },
    {
        file: "images/art/fanart/papyton.png",
        alt: "a digital drawing of Papyrus and Mettaton from Undertale. Papyrus is dressed in a candy striper's outfit with a fitted orange vest, and Mettaton is wearing a pink crop top. They are walking with one arm around the other.",
        caption: 'Fanart - "Vintage Outfits for Monsters"'
    },
    /*{
        file: "images/art/commissions/Clover.png",
        alt: "a digital drawing of a short, plump Black woman wearing a green dress, holding flowers. Her hair is in two buns.",
        caption: 'Character commission - "Clover"'
    },
    {
        file: "images/art/commissions/Clementine.png",
        alt: "a digital drawing of a tall, lean person with tattoos and a prosthetic arm. They are wearing brown and green clothing and a sheathed sword with a golden pommel.",
        caption: 'Character commission - "Clementine"'
    },
    {
        file: "images/art/commissions/Livy.png",
        alt: "a digital drawing of a tired-looking blond woman leaning back, holding a cane. She is wearing several joint braces and clothing with mushrooms on it.",
        caption: 'Character commission - "Livy"'
    },*/
    {
        file: "images/art/commissions/vryn.png",
        alt: "a digital drawing of a dark-skinned man with long, wavy red hair. He wears red armor and a large battleaxe strapped to his back.",
        caption: 'Character commission - "Vryn"'
    },
    /*{
        file: "images/art/commissions/ari-icon.png",
        alt: "a digital drawing of an anthropomorphic brown and blue-grey pine marten. they are smiling and surrounded by red poppies.",
        caption: 'Character commission - "PineTwig"'
    },
    {
        file: "images/art/commissions/frenchy0010.png",
        alt: "a digital drawing of wrench from watchdogs and a dark-haired woman, half-hugging, with the woman pointing a gun at the viewer",
        caption: 'Character commission - "Wrench and Malware"'
    }, 
    {
        file: "images/art/commissions/CavalricWordsmith.png",
        alt: "a digital character reference sheet for an anthropomorphic hybrid of a bear, porcupine, kestrel, and dog. Their fur is patterned with a bowling alley carpet pattern.",
        caption: 'Character Design commission - "Bear/Jay"'
    },
    {
        file: "images/art/commissions/thewrit.png",
        alt: "a digital drawing of two lavender quills, arched into the shape of a heart, with two swirls inside representing a scroll", 
        caption: 'Special commission - Emblem for "The Writ"'
    }*/
    {
        file: "images/art/commissions/emmacomm.jpg",
        alt: "a digital drawing of moira from overwatch and a dark-haired woman dancing, wearing costumes from The Labyrinth. They are surrounded by an art nouveau frame.",
        caption: 'Character commission - "Moira and Emma"'
    }
];

const DigitalSlider = (props) => {
    return <Slider data={digital} sliderName="digital" />
}

export default DigitalSlider;
