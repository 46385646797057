import React from 'react';
import Slider from './SliderBase';

// CONTENT // 
const commissions = [
  {
      file: "images/art/commissions/forwin.png",
      alt: "A grey schnauzer with goat horns and red eyes, glaring nefariously into the camera with evil steeple hands",
      caption: 'Character commission - Stampede'
  },
  {
      file: "images/art/commissions/dahlia.png",
      alt: "a front-view reference sheet of a brown and purple hellhound with green horns and a green glyph on her chest. she has long brown hair with purple highlights",
      caption: 'Character design commission - "Dahlia"'
  },
  {
      file: "images/art/commissions/dahlia icon.png",
      alt: "a side view painting of a purple and brown hellhound with green horns",
      caption: 'Character icon - "Dahlia"'
  },
  {
      file: "images/art/commissions/charliesona.png",
      alt: "a reference sheet of the front and back views of a brown anthropomorphic rabbit with long green curly hair",
      caption: 'Character design commission - "Charlie"'
  },
  {
      file: "images/art/commissions/barkeep.png",
      alt: "a digital drawing of a heavy-set barkeeper with freckles and brown hair, cleaning a tankard as he smiles softly.",
      caption: 'Character commission - "Mülpith"'
  },
  {
      file: "images/art/commissions/patron.png", 
      alt: "a digital drawing of a red-haired orc with green skin, his head in his hands as he looks, lovestruck, towards the viewer. Behind him are three blurred figures.",
      caption: 'Character commission - "Sir Anderson"'
  },
  /*{
      file: "images/art/commissions/Clover.png",
      alt: "a digital drawing of a short, plump Black woman wearing a green dress, holding flowers. Her hair is in two buns.",
      caption: 'Character commission - "Clover"'
  },
  {
      file: "images/art/commissions/Clementine.png",
      alt: "a digital drawing of a tall, lean person with tattoos and a prosthetic arm. They are wearing brown and green clothing and a sheathed sword with a golden pommel.",
      caption: 'Character commission - "Clementine"'
  },
  {
      file: "images/art/commissions/Livy.png",
      alt: "a digital drawing of a tired-looking blond woman leaning back, holding a cane. She is wearing several joint braces and clothing with mushrooms on it.",
      caption: 'Character commission - "Livy"'
  },
  {
      file: "images/art/commissions/frenchy0010.png",
      alt: "a digital drawing of wrench from watchdogs and a dark-haired woman, half-hugging, with the woman pointing a gun at the viewer",
      caption: 'Character commission - "Wrench and Malware"'
  },
  {
      file: "images/art/commissions/ari-icon.png",
      alt: "a digital drawing of an anthropomorphic brown and blue-grey pine marten. they are smiling and surrounded by red poppies.",
      caption: 'Character commission - "PineTwig"'
  }, */
  {
      file: "images/art/commissions/vryn.png",
      alt: "a digital drawing of a dark-skinned man with long, wavy red hair. He wears red armor and a large battleaxe strapped to his back.",
      caption: 'Character commission - "Vryn"'
  },
  {
      file: "images/art/commissions/emmacomm.jpg",
      alt: "a digital drawing of moira from overwatch and a dark-haired woman dancing, wearing costumes from The Labyrinth. They are surrounded by an art nouveau frame.",
      caption: 'Character commission - "Moira and Emma"'
  },
  {
      file: "images/art/commissions/CavalricWordsmith.png",
      alt: "a digital character reference sheet for an anthropomorphic hybrid of a bear, porcupine, kestrel, and dog. Their fur is patterned with a bowling alley carpet pattern.",
      caption: 'Character Design commission - "Bear/Jay"'
  }
];

const CommSlider = (props) => {
    return <Slider data={commissions} sliderName="comms" />
}

export default CommSlider;
