import React from 'react';
import '../style.css'

import { BoxTitle } from '../reusable/titles.js';
import WebsitesSlider from '../sliders/WebsitesSlider.js'
import SinglePageSitesSlider from '../sliders/SinglePageSitesSlider';
import { DevSocials } from '../reusable/socials';

export default class Webdev extends React.Component {

    render() {
        return (
            <main id="website-design">
                <section className="boxed box-section hold-slider" id="work">
                    <BoxTitle text="Recent Work" />

                    <div className="box-body">
                        <WebsitesSlider />
                    </div>
                </section>

                <section className="boxed box-section narrow-box" id="certification">
                    <BoxTitle text="Certifications" />
                    
                    <div className="box-body">
                        <h3 className="inner">JavaScript Algorithms and Data Structures Certification</h3>
                        <p><a href="https://www.freecodecamp.org/certification/curiositybard/javascript-algorithms-and-data-structures">View Certificate</a></p>
                        <h3 className="inner">Responsive Web Design Certification</h3>
                        <p><a href="https://www.freecodecamp.org/certification/curiositybard/responsive-web-design">View Certificate</a></p>
                        <h3 className="inner">Back End Development and APIs Certification</h3>
                        <p><a href="https://www.freecodecamp.org/certification/curiositybard/back-end-development-and-apis">View Certificate</a></p>
                        <h3 className="inner">Front End Development Libraries Certification</h3>
                        <p><a href="https://www.freecodecamp.org/certification/curiositybard/front-end-development-libraries">View Certificate</a></p>
                    </div>
                </section>

                <section className="boxed box-section hold-slider" id="examples">
                    <BoxTitle text="Single-Page Examples" />

                    <div className="box-body hold-slider">
                        <p>These are examples of front-end development only. Visit <a href="https://codepen.io/curiositybard" target="_blank" rel="noreferrer">my Codepen profile</a> to see them in action.</p>
                        <SinglePageSitesSlider />
                    </div>
                </section>

                <div id='skills-links'>
                    <section className="boxed box-section narrow-box" id="skills">
                        <BoxTitle text="Skills" />
                        
                        <div className="box-body">
                            <ul id="skillset">
                                <li>HTML</li>
                                <li>CSS & Sass/SCSS</li>
                                <li>JavaScript</li>
                                <ul>
                                    <li>React</li>
                                    <li>Node.js</li>
                                    <li>Express</li>
                                </ul>
                                <li>Python</li>
                                <li>Wordpress</li>
                                <ul>
                                    <li>Theming with Bones</li>
                                    <li>Setup & Design</li>
                                </ul>
                            </ul>
                        </div>
                    </section>
                    <section className="boxed box-section narrow-box" id="dev-links">
                        <BoxTitle text="Links" />
                        
                        <div className="box-body">
                            <DevSocials />
                        </div>
                    </section>
                </div>

                <section className="boxed box-section narrow-box" id="about-site">
                    <BoxTitle text="About This Site" />
                    <div className="box-body">
                        <p>This website was designed and built by by Briar. Made from scratch using HTML, Sass & CSS, JavaScript, and React. The back-end is a NodeJS & Express app.</p>
                    </div>
                </section>                
            </main>
        )
    }
}