import React from 'react';
import Slider from './SliderBase';

// CONTENT // 
const inktoberimg = [
    // add other ones here 
    {
        file: "images/art/inktober23/1.jpg",
        alt: "an ink drawing of Mettaton from undertale, standing casually under 2 spotlights.",
        caption: 'Day 1: Celebrities'
    },
    {
        file: "images/art/inktober23/2.jpg",
        alt: "an ink drawing of a water elemental in a sundress, sitting underneath a tree with somewhat sparse branches.",
        caption: 'Day 2: Tree'
    },
    {
        file: "images/art/inktober23/3.jpg",
        alt: "an ink drawing of Winry Rockbell and Edward Elric from Fullmetal Alchemist with looks of joy on their faces as they embrace",
        caption: 'Day 3: Joy'
    },
    {
        file: "images/art/inktober23/4.jpg",
        alt: "an ink drawing of various bugs crawling out from under a rock",
        caption: 'Day 4: Life'
    },
    {
        file: "images/art/inktober23/5.jpg",
        alt: "an ink drawing of a cyclops character playing wheelchair basketball with two other characters mostly out of frame",
        caption: 'Day 5: Sports'
    },
    {
        file: "images/art/inktober23/6.jpg",
        alt: "an ink drawing of an anthropomorphic dragon grinning and wearing an asymmetrical cape",
        caption: 'Day 6: Reptile'
    },
    {
        file: "images/art/inktober23/7.jpg",
        alt: "an ink drawing of several labelled pasta shapes: farfalle, cavatappi, fusilli, macaroni, penne, rigatoni, rotelle, ravioli, and tortellini",
        caption: 'Day 7: Food'
    },
    {
        file: "images/art/inktober23/8.jpg",
        alt: "an ink drawing of the artist, wrapped up in a blanket and lying down",
        caption: 'Day 8: You!'
    },
    {
        file: "images/art/inktober23/9.jpg",
        alt: "an ink drawing of Brooke, a water elemental, shyly standing in a sweater with a heart sewn on the front",
        caption: 'Day 9: Sweater'
    },
    {
        file: "images/art/inktober23/10.jpg",
        alt: "an ink drawing of an astronaut, floating in space, surrounded by two jellyfish",
        caption: 'Day 10: Fascination'
    },
    {
        file: "images/art/inktober23/11.jpg",
        alt: "an ink drawing of a classical portrayal of death as a skeleton in a long cape, holding a scythe",
        caption: 'Day 11: Death'
    }
];

const InktoberSlider = (props) => {
    return <Slider data={inktoberimg} sliderName="inktober" />
}

export default InktoberSlider;
