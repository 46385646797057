import React from "react";
import '../style.css';

import { BoxTitle } from '../reusable/titles.js';
import InktoberSlider from "../sliders/InktoberSlider";

export default class Inktober extends React.Component {
    render() {
        return (
            <main className="tile-holder" id="inktober-container">
                <section id="inktober-intro" className="boxed box-section tiled">
                    <BoxTitle text="Inktober" />
                    <div className="box-body">
                        <p>It's that time of year again - the air is getting crisp and cool, the leaves are falling from the trees, and artists everywhere are feeling the pressure of Inktober.</p>
                        <p>I've decided this is the year I'll finish it. Inktober 2023 will be my year. I've planned ahead and I'm ready to go.</p>
                        <p>Would you like to join me? Here are my prompts for this month - a mix of things I want to draw, things I need to learn to draw, and whatever else happened to come to mind. Feel free to get wild with it! Take these prompts not as a strict guideline, but as a jumping-off point for something fun for you. Each day of the week has a different category for prompts, and each day has the prompt itself.</p>
                        <h2 className="inner-title">Prompts:</h2>
                        <ol className="prompt-list">
                            <li>Celebrities</li>
                            <li>Tree</li>
                            <li>Joy</li>
                            <li>Life</li>
                            <li>Sports</li>
                            <li>Reptile</li>
                            <li>Food</li>
                            <li>You!</li>
                            <li>Sweater</li>
                            <li>Fascination</li>
                            <li>Death</li>
                            <li>Fighting</li>
                            <li>Mammal</li>
                            <li>Nature</li>
                            <li>OCs</li>
                            <li>Camera</li>
                            <li>Grief</li>
                            <li>Progress</li>
                            <li>Dancing</li>
                            <li>Fish</li>
                            <li>Fashion</li>
                            <li>Family</li>
                            <li>Bones</li>
                            <li>Boredom</li>
                            <li>Decay</li>
                            <li>Building</li>
                            <li>Amphibian</li>
                            <li>Mythology</li>
                            <li>Favorite Characters</li>
                            <li>Tent</li>
                            <li>Spooky</li>
                        </ol>
                    </div>
                </section>
                <section id="inktober-calendar-box" className="boxed box-section tiled">
                    <BoxTitle text="Inktober 2023 Calendar" />
                    <img src="images/calendar.jpg" alt="a calendar of drawing prompts, which are all listed below. The days of the week at the top are labelled with different categories - people, objects, vibes, concepts, activities, animals, and miscellaneous. The top says Briar's Inktober 2023, and at the bottom are social media handles and the link to this website, along with instructions to tag #inktoberwithbriar if you use the prompt." id="inktober-calendar" className="boxImg" />
                </section>
                <section id="inktober-slider-box" className="boxed box-section tiled">
                    <BoxTitle text="Inktober Drawings" />
                    <InktoberSlider />
                </section>
            </main>
        );
    }
}