import React from 'react';
import Slider from './SliderBase';

// CONTENT // 
const physical = [
    {
        file: "images/art/wizard.jpg",
        alt: "a mixed media painting of a wizard in his study, casting a spell to paint a universe in front of him",
        caption: 'Mixed media - "Hubris"'
    },
    {
        file: "images/art/visitor1.jpg",
        alt: "A photo of three sculpted tentacles, one with a brown eye at the end, reaching out of a round porthole in front of a swirling blue background.",
        caption: 'Polymer clay and acrylic on a metal base - "Visitor"'
    },
    {
        file: "images/art/visitor2.jpg",
        alt: "A photo of three sculpted tentacles, one with a brown eye at the end, reaching out of a round porthole in front of a swirling blue background.",
        caption: 'Polymer clay and acrylic on a metal base - "Visitor"'
    },
    {
        file: "images/art/visitor3.jpg",
        alt: "A photo of three sculpted tentacles, one with a brown eye at the end, reaching out of a round porthole in front of a swirling blue background.",
        caption: 'Polymer clay and acrylic on a metal base - "Visitor"'
    },
    {
        file: "images/art/terrain.jpg",
        alt: "A layered painting of striated orange rocks in the form of a hill and an arch, in front of a swirling blue and yellow background.",
        caption: 'Gouache and acrylic layer painting - "Terrain"'
    },
    {
        file: "images/art/hollow.jpg",
        alt: "A layered painting of a dead, hollow tree over a crackly blue background, with two red eyes peering out of the hollow of the tree.",
        caption: 'Gouache and acrylic layer painting - "Hollow"'
    },
    {
        file: "images/art/dragon1.jpg",
        alt: "A photo of a painting of a dragon whose sculpted talons are reaching out of a gold frame",
        caption: 'Acrylic and polymer clay - "Peeking Dragon"'
    },
    {
        file: "images/art/dragon2.jpg",
        alt: "A photo, taken at a tilted angle, of a painting of a dragon whose sculpted talons are reaching out of a gold frame",
        caption: 'Acrylic and polymer clay - "Peeking Dragon"'
    },
    {
        file: "images/art/brookesculpt1.jpg",
        alt: "A photo of a sculpture of a water elemental singing into a vintage microphone, standing on a stage surrounded by gold shell lights. She is wearing a violet cape and a white and gold dress.",
        caption: 'Polymer clay, acrylic, and miscellaneous materials - "Brooke Irving"'
    },
    {
        file: "images/art/brookesculpt2.jpg",
        alt: "A photo of a sculpture of a water elemental singing into a vintage microphone, standing on a stage surrounded by gold shell lights. She is wearing a violet cape and a white and gold dress.",
        caption: 'Polymer clay, acrylic, and miscellaneous materials - "Brooke Irving"'
    },
    {
        file: "images/art/brookesculpt3.jpg",
        alt: "A photo of a sculpture of a water elemental singing into a vintage microphone, standing on a stage lit by shell lights. She is wearing a violet cape and a white and gold dress.",
        caption: 'Polymer clay, acrylic, and miscellaneous materials - "Brooke Irving"'
    },
    {
        file: "images/art/brookesculpt4.jpg",
        alt: "A photo of a sculpture of a water elemental singing into a vintage microphone, standing on a stage lit by shell lights. She is wearing a violet cape and a white and gold dress.",
        caption: 'Polymer clay, acrylic, and miscellaneous materials - "Brooke Irving"'
    },
    {
        file: "images/art/tentacle1.jpg",
        alt: "A photo of a sculpture of a purple tentacle reaching out of a swirling background.",
        caption: 'Polymer clay and acrylic - "Breaking Out"'
    },
    {
        file: "images/art/tentacle2.jpg",
        alt: "A photo of a sculpture of a purple tentacle reaching out of a swirling background.",
        caption: 'Polymer clay and acrylic - "Breaking Out"'
    },
    {
        file: "images/art/tentacle3.jpg",
        alt: "A photo of a sculpture of a purple tentacle reaching out of a swirling background.",
        caption: 'Polymer clay and acrylic - "Breaking Out"'
    },
    {
        file: "images/art/tentacle4.jpg",
        alt: "A photo of a sculpture of a purple tentacle reaching out of a swirling background.",
        caption: 'Polymer clay and acrylic - "Breaking Out"'
    },
    {
        file: "images/art/fanart/gaster.jpg",
        alt: "A spooky ink drawing of Gaster from Undertale, with scrawled wingdings characters around him spelling out 'wanna watch Bill Nye the Science Guy?'",
        caption: 'Fanart - Ink - "A Friendly Gaster"'
    },
    {
        file: "images/art/cockatrice1.jpg",
        alt: "A photo of an altered chicken puzzle, now a cockatrice with green tail and talons, breathing fire made of cellophane. The cellophane is lit from behind.",
        caption: 'Altered puzzle - Acrylic and cellophane - "Cockatrice"'
    },
    {
        file: "images/art/cockatrice2.jpg",
        alt: "A photo of an altered chicken puzzle, now a cockatrice with green tail and talons, breathing fire made of cellophane.",
        caption: 'Altered puzzle - Acrylic and cellophane - "Cockatrice"'
    },
    {
        file: "images/art/smallspace.jpg",
        alt: "A painted square space-scape with two planets, a moon, and a satellite breaking out of the frame",
        caption: 'Layered acrylic painting - "A Small Space"'
    },
    {
        file: "images/art/polar.jpg",
        alt: "A layered painting of a polar bear, seen through snow-covered arches of ice, against an abstract polar background in hues of blue and white",
        caption: 'Layered acrylic painting - "Polar"'
    },
    {
        file: "images/art/girlghost.jpg",
        alt: "An acrylic painting of a torn, ghostly girl over a blurry mirror, surrounded by a scratched and blood-spattered frame.",
        caption: 'Acrylic on a mirror - "Definitely Haunted"'
    },
    {
        file: "images/art/journeyhome.jpg",
        alt: "A layered painting of a rocketship with fire of paint and cellophane curving behind it, tracing its path from a distant moon to a larger planet",
        caption: 'Layered acrylic paintings - "The Journey Home"'
    },
    {
        file: "images/art/frogs.jpg",
        alt: "An acrylic pour painting in hues of green and white, with small and tiny origami frogs scattered among the bottom half. A tiny frog is sitting atop one of the small frogs.",
        caption: 'Acrylic pour and origami - "Frog Family"'
    },
    {
        file: "images/art/Astronaut.jpg",
        alt: "A layered painting of an astronaut floating in space, looking into the distance at the silhouette of a spaceship floating above a large planet in the background",
        caption: 'Layered acrylic paintings - "The Astronaut\'s Perspective"'
    },
    {
        file: "images/art/fanart/scorpia.jpg",
        alt: "A painting of the scene from She-Ra: Princesses of Power where Scorpia holds up the caving in roof of the underwater bar, flexing her back muscles.",
        caption: 'Fanart - Acrylic - "I\'m the Muscle, Remember?"'
    }
];

const PhysicalSlider = (props) => {
    return <Slider data={physical} sliderName="physical" />
}

export default PhysicalSlider;