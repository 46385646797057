export const Preload = () => {
    return (
        <div id="hide-preload">
            <img alt="" src="TRANSP-LIGHT-COMM-SHEET.png" />
            <img alt="" src="art/alec.png" />
            <img alt="" src="art/anyun.PNG" />
            <img alt="" src="art/Astronaut.jpg" />
            <img alt="" src="art/bardref.png" />
            <img alt="" src="art/bearold.png" />
            <img alt="" src="art/brooke.png" />
            <img alt="" src="art/brookesculpt1.jpg" />
            <img alt="" src="art/brookesculpt2.jpg" />
            <img alt="" src="art/brookesculpt3.jpg" />
            <img alt="" src="art/brookesculpt4.jpg" />
            <img alt="" src="art/cockatrice1.jpg" />
            <img alt="" src="art/cockatrice2.jpg" />
            <img alt="" src="art/dragon1.jpg" />
            <img alt="" src="art/dragon2.jpg" />
            <img alt="" src="art/frogs.jpg" />
            <img alt="" src="art/girlghost.jpg" />
            <img alt="" src="art/journeyhome.jpg" />
            <img alt="" src="art/polar.jpg" />
            <img alt="" src="art/smallspace.jpg" />
            <img alt="" src="art/tentacle1.jpg" />
            <img alt="" src="art/tentacle2.jpg" />
            <img alt="" src="art/tentacle3.jpg" />
            <img alt="" src="art/tentacle4.jpg" />
            <img alt="" src="art/fanart/scorpia.jpg" />
            <img alt="" src="art/fanart/gaster.jpg" />
            <img alt="" src="art/fanart/first-day-of-spring.jpg" />
            <img alt="" src="art/commissions/ari-icon.png" />
            <img alt="" src="art/commissions/bossfight.png" />
            <img alt="" src="art/commissions/CavalricWordsmith.png" />
            <img alt="" src="art/commissions/Clementine.png" />
            <img alt="" src="art/commissions/Clover.png" />
            <img alt="" src="art/commissions/emmacomm" />
            <img alt="" src="art/commissions/frenchy0010.png" />
            <img alt="" src="art/commissions/Livy.png" />
            <img alt="" src="art/commissions/thewrit.png" />
            <img alt="" src="art/commissions/vryn.png" />
        </div>
    );
}