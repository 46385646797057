import React from 'react';
import '../style.css';

import CommissionSlider from '../sliders/CommSlider';
import { BoxTitle } from '../reusable/titles.js';
import { ArtSocials } from '../reusable/socials';

class Commissions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            commSheetColor: 'light',
            commSheetLink: 'images/TRANSP-LIGHT-COMM-SHEET.png'
        }

        this.changeCommSheet = this.changeCommSheet.bind(this);
    }

    changeCommSheet() {    
        let darkLink = "images/TRANSP-LIGHT-COMM-SHEET.png";
        let lightLink = "images/DARK-COMM-SHEET.png"

        let to = this.state.commSheetColor;
    
        if (to === 'light') {
            this.setState({
                commSheetColor: 'dark',
                commSheetLink: lightLink
            }, () => {
                console.log('sheet changed to light');
            });
        } else if (to === 'dark') {
            this.setState({
                commSheetColor: 'light',
                commSheetLink: darkLink
            }, () => {
                console.log('sheet changed to dark');
            });
        } else {
            console.log('something went wrong');
        }
    }

    render() {
        return (
            <>
                <section id="commissions-top-stuff">
                    <div id="comm-sheet">   
                        <img src="images/commission sheet - lineless.png" id="linelessSheet" alt="Digital painting of an anthropomorphic saber-toothed cat wearing shorts and a crop top, labelled with pricing found below" className="boxed boxImg" />
                        {/*<img src={this.state.commSheetLink} id="sheetImage" alt="Digital drawing of a pointy-eared, horned man with two prosthetic legs holding his chin with his hand, with the upper right half of the drawing shaded. Details on my commission information surround him; all details are found in text on this page." className="boxed boxImg" />*/}
                    </div> 
                    <div className="top-notes">
                        <p className="commission-top-note boxed box-note">Commissions are currently open! Email me at <a className="commission-top-links" href="mailto:briar@curiositybard.com" target="_blank" rel="noreferrer">briar@curiositybard.com</a></p>
                        <p className="commission-top-note boxed box-note" id="recent-link"><a href="#recent" className="commission-top-links">Click here</a> to scroll to my recent commission work.</p>
                        <p className="commission-top-note boxed box-note" id="cartoon-announcement">Cartoon and mixed media pricing available upon request.</p>
                        {/*<p className="commission-top-note boxed box-note" ><button id="click-to-change" onClick={this.changeCommSheet}>Click here</button> to see the commission sheet with {this.state.commSheetColor === 'light' ? 'dark' : 'light'} text on a {this.state.commSheetColor === 'light' ? 'light' : 'dark'} background.</p>*/}
                    </div>
                </section>
                <main id="commissions-main">
                    <section id="process" className="boxed box-section">
                        <BoxTitle text="Process" />
                        <div className="box-body">
                            <h3 className="small-head">Payment</h3>
                            <p>Once we’ve discussed pricing and your request, I’ll send an invoice with the details and amount owed. Commissions under $100 total must be paid in full before entering my work queue. Commissions over $100 total require a 50% deposit before entering my work queue. I am open to discussing other payment options for larger projects, non-profit community actions/organizations, and other extenuating circumstances.</p>
                            <p>Commissions must be paid via invoice (payable by credit/debit card or PayPal), unless you can pay cash in person – for example, at an event or art show. </p>
                            <p>Payment in full is <em>required</em> prior to delivery of full-resolution image files.</p>
                            <h3 className="small-head">Process</h3>
                            <p>I will directly send frequent updates for commissions. Except for commissions specified as “artistic freedom” projects, updates will be delivered – at minimum – upon completion of each stage of the project. Character design projects will receive more updates, especially in earlier stages, to confirm design elements. For cartoon character commissions, you can expect to receive progress shots for revisions/approval after completion of the sketch, lineart, and color. For lineless illustration commissions, you can expect progress shots after completion of the rough sketch, refined sketch, and flat color. </p>
                            <p>One round of revisions is included in pricing at each stage, and additional or complicated revisions may incur additional charges. For example, if you want to change the pose your character is in at the line art or color stage, there will be an additional charge. However, if you decide when you receive the sketch that you want to adjust the pose, it’s free to change it the first time. Mistakes on my part, except when they conflict with provided references, are also free to correct. </p>
                            <p>One round of revisions is included in pricing at each stage, and additional or complicated revisions may incur additional charges. For example, if you want to change the pose your character is in at the line art or color stage, there will be an additional charge. However, if you decide when you recieve the sketch that you want to adjust the pose, it's free to change it the first time.</p>
                            <p>Character design commissions allow for further revisions included in the price, and I will let you know before the limit is reached. If charges will be incurred by more changes, I will always allow at least one more round of revisions after notifying you. Major reworking or total redesigns after the sketch is approved would fall under complicated revisions and require additional payment.</p>
                            <h3 className="small-head">Completion and Delivery</h3>
                            <p>Digital commissions will be delivered via email in most cases. Completed commissions can also be delivered via Direct Message on social media or Discord upon request, but due to compression, may not arrive at full resolution/quality.</p>
                            <p>Digital works will be delivered at print resolution (I default to 300 or 350 dpi). Character commissions without complex backgrounds will also receive a transparent version. Transparent versions can be requested for commissions with complex backgrounds as well. All commissions will be delivered with a “clean” version (no watermark) for personal use, as well as a watermarked version that can be used to credit me for the artwork when sharing publicly online.</p>
                            <p>Traditional commissions, paintings, prints, etc. will be mailed as agreed upon during the planning/ordering stage of the project. Please make sure to provide an up-to-date mailing address and the name you want on the package in order to recieve a physical product. Commissions are sent via USPS in most cases, with sufficient insurance to cover the cost of the product in case of loss/damage in shipping. For more information on what USPS shipping insurance covers, please visit the <a href="https://faq.usps.com/s/article/What-is-Insurance">USPS FAQ - What is insurance?</a> page. I am not responsible for the fate of packages once they have left my possession. You will be provided with a tracking number as proof of shipment as soon as the project is sent out.</p>
                            <p>Please note that shipping prices are calculated per-project, based on the cost of postage and packaging, and are not included in base commission prices.</p>
                            <h3 className="small-head">Cancellation and Refunds</h3>
                            <p>If you cancel a project before work has begun, you can request a full refund.</p>
                            <p>If you cancel a project after any supplies have been purchased, or after work has begun, you may be eligible for a partial refund, depending on the state the project is at. Partial refunds are <em>not</em> available for any digital projects after completion of the line art stage, unless other terms are agreed upon <em>in writing</em> during the planning stage.</p>
                            <p>If, for any reason, you must cancel a commission, please contact me as soon as possible.</p>
                            <p>Cancellation/refunding of a project will remove you from my work queue. If you need to put a hold on the project but want to keep your place in the queue, I cannot provide a refund.</p>
                        </div>
                    </section>
                    <div id="left-side">
                        <section id="pricing" className="boxed box-section">
                            <BoxTitle text="Lineless Illustration Pricing" />
                            <div className="box-body">
                                <h4 className="quick-note">All pricing is in $ USD.</h4>
                                <h3 className="small-head">Per Character:</h3>
                                <ul className="no-bullet">
                                    <li><em>Icons</em> - $70 each.</li>
                                    <li><em>Bust</em> - $100 per character.</li>
                                    <li><em>Half Body</em> - $120 per character.</li>
                                    <li><em>Full Body</em> - $160 per character.</li>
                                </ul>
                                <h3 className="small-head">Add-ons:</h3>
                                <ul className="no-bullet">
                                    <li><em>Backgrounds</em> - A simple block color or gradient/pattern background is included in standard commission pricing. Complex backgrounds start at $50.</li>
                                    <li><em>Complexity</em> - Especially complex characters, outfits, and accessories may incur additional charges. Mobility aids & other assistive devices for disabled characters do not cost extra.</li>
                                    <li><em>Characters without visual reference</em> - Characters without sufficient visual reference material may also require additional charges to cover design revisions and additional time required.</li>
                                    <li><em>Character design fees</em> - Similarly, designing a new character from the ground up for a commission will incur additional charges. This cost is built in to quotes for character reference sheets.</li>
                                </ul>
                                <p>Any additional charges will be clarified prior to ordering & invoicing, or as soon as new additions/revisions are requested on a work in progress.</p>
                            </div>
                        </section>
                        <section id="recent" className="boxed box-section">
                            <BoxTitle text="Recent Commission Work" />
                            <div className="box-body">
                                <noscript>Please enable JavaScript to view this gallery.</noscript>
                                <div id="commissions-slider">
                                    <CommissionSlider />
                                </div>
                            </div>
                        </section>
                        <section id="contact" className="boxed box-section">
                            <BoxTitle text="Contact Information" />
                            <div className="box-body">
                                <p>To contact me for commissions, you can DM me on social media or email me at <a href="mailto:briar@curiositybard.com">briar@curiositybard.com</a>.</p>
                                <ArtSocials />
                            </div>
                        </section>
                    </div>
                    
                </main>
            </>
        );
    }
}

export default Commissions;

