import { useState } from "react";

const Dots = (props) => {
    return props.data.map((e, i) => {
        return <span key={"recent" + i} id={"recentDot" + i} className={props.active === i ? "dot activedot" : "dot"} onClick={() => props.changeToSetImage(i)}>⬤</span>
    });
}

const Slider = (props) => {
    // Slider initializes with first image in the array passed as props.data
    const [imageNum, setImageNum] = useState(0);
    const [image, setImage] = useState(props.data[0].file);
    const [alt, setAlt] = useState(props.data[0].alt);
    const [caption, setCaption] = useState(props.data[0].caption);
    const [loaded, setLoaded] = useState(false);

    const changeToNewImage = (n) => {
        setImageNum(n);
        setImage(props.data[n].file);
        setAlt(props.data[n].alt);
        setCaption(props.data[n].caption);
        setLoaded(false);
    }

    // Change the image forwards (1) or backwards (-1) by one 
    const changeImageByOne = (n) => {
        let num = imageNum;
        // move left:
        if (n === -1) {
            if (imageNum === 0) {
                num = props.data.length - 1;
            } else {
                num -= 1;
            }
        }
        // move right:
        if (n === 1) {
            if (imageNum === props.data.length - 1) {
                num = 0;
            } else {
                num += 1;
            }
        }
         
        changeToNewImage(num);
    }
    const goBackOne = () => {
        changeImageByOne(-1);
    }
    const goForwardOne = () => {
        changeImageByOne(1);
    }

    const imageLoaded = () => {
        setLoaded(true);
    }

    return (
        <div id={props.sliderName} className="slider-complex">
            <figure id={props.sliderName + "fig"} className="fit-picture">
                <img className="slider-img" src={loaded ? image : "images/loading.gif"} alt={alt} onLoad={imageLoaded} />
                <figcaption>{caption}</figcaption>
            </figure>

            <div id={props.sliderName + "slider-control"} className="slider-control">
                <button className="slider-back" onClick={goBackOne}>Previous</button>
                <div className="dots">
                    <Dots active={imageNum} changeToSetImage={changeToNewImage} data={props.data} />
                </div>
                <button className="slider-forward" onClick={goForwardOne}>Next</button>
            </div>
        </div>
    );
}

export default Slider;