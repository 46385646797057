import React from 'react';
import '../style.css'

import { BoxTitle } from '../reusable/titles.js';
import RecentSlider from '../sliders/RecentSlider.js';

export default class Home extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <main className="tile-holder">
                {/* <section id="announcement" className="boxed box-section fullwidth">
                    <BoxTitle text="Make a Wish - Available Now!" />
                    <div className="box-body">
                        <a href="https://rawpaw.ink/products/make-a-wish-by-curiositybard" target="_blank" rel="noreferrer"><img src="images/art/make a wish promo.png" alt="an advertisement for a t-shirt with pink and purple planets and stars, along with a comet in the foreground, which is worn by a fat person with pink hair" id="promo" className="inTextFlow" /></a>
                        <div id="promotext">
                            <p>Exciting news! I have a T-shirt available for sale now as part of the "Day vs. Night" challenge by <a href="https://rawpaw.ink" target="_blank" rel="noreferrer">Raw Paw</a>. As long as 10 sell, you'll get a radical space-themed shirt by me! You'll also be supporting me directly, as I get paid for every sale. If less than 10 sell, you'll be refunded - but I'd really appreciate if you share or buy the shirt to make that minimum happen! Visit my social media to share posts advertising the shirt, or you can just share the link to my website or <a href="https://rawpaw.ink/products/make-a-wish-by-curiositybard" target="_blank" rel="noreferrer">the shirt itself, which is right here!</a></p>
                            <p>This art challenge is a great opportunity for me, but I need help from my friends, family, and fans to make it a successful one. I really appreciate you sharing or buying the shirt! Any support means the world to me.</p>
                            <a href="https://rawpaw.ink/products/make-a-wish-by-curiositybard" target="_blank" rel="noreferrer"><h1>BUY NOW!</h1></a>
                        </div>
                    </div>
                </section> */}
                <section id="introducing" className="boxed box-section tiled">
                    <BoxTitle text="Who am I?" />
                    <div className="box-body">
                        <img src="images/selfportNew.png" alt="a cartoon self portrait of Briar" id="portrait" className="inTextFlow" />
                        <div id="quickabout">
                            <p> Hey there! Welcome to my home page. My name is Briar, and I'm an artist from the Pacific Northwest. I love creating things, from artwork to websites and more. Some of my creative interests include writing, mixed media art, sculpture, digital art, painting, video and tabletop game design, web design and development, and character design. If you'd like to check out my work, here's the place to do it! You can also find my social media links below for more recent updates. Thanks for visiting!</p>
                            <h3 className="small-head">For Artwork Seekers -</h3>
                            <p>If you're an individual looking for some custom - or pre-made - artwork, 
                            check out my <a href='/portfolio'>art portfolio</a> for work I've done 
                            in the past, or take a look at my <a href="/commissions">commissions</a> page 
                            for details on my pricing and how to order a commission.</p>
                            {/* <p>Looking for a pre-made art piece? check out my <a href="https://www.etsy.com/shop/CuriosityBard" target="_blank" rel="noreferrer">Etsy store</a>.</p> */}
                            <h3 className="small-head">For Recruiters and Freelance -</h3>
                            <p>If you're a recruiter, seeking a freelancer, or interested in a large project, please <a href="mailto:briar@curiositybard.com" target="_blank" rel="noreferrer">email me.</a></p> 
                        </div>
                    </div>
                </section>
                
                <section id="recent-art" className="boxed box-section narrow-box hold-slider no-tile">
                    <BoxTitle text="Recent Art" />
                    <div className="box-body">
                        <RecentSlider />
                    </div>
                </section>
                {/*<section id="recent-web" className="boxed box-section narrow-box hold-slider no-tile fit-content">
                    <BoxTitle text="Recent Websites" />
                    <div className="box-body">
                        <RecentWebSlider />
                    </div>
                </section>
                <section id="homelinks" className="boxed box-section narrow-box hold-slider no-tile fit-content">
                    <BoxTitle text="Links" />
                    <div className="box-body">
                        <ArtSocials />
                    </div>
                </section>*/}

                {/* <section id="art-box" className="boxed box-section tiled fit-content">
                    <BoxTitle text="Artwork" />
                    <div className="box-body">
                        <figure className="cover-fig" ><a className="cover-link" href="/portfolio">
                            <img src="images/art/brookesculpt3.jpg" alt="A sculpture of a water elemental singing into a vintage microphone, standing on a stage lit by shell lights" className="cover" />
                            <figcaption className="under-cover">Check out my art gallery for more digital and mixed media art pieces.</figcaption>
                        </a></figure>
                    </div>
                </section>

                <section id="web-dev-box" className="boxed box-section tiled fit-content">
                    <BoxTitle text="Web Development" />
                    <div className="box-body">
                        <figure className="cover-fig" ><a className="cover-link" href="/webdev">
                            <img src="images/codeshot.png" alt="a stretched screenshot of code from this website" className="cover" />
                            <figcaption className="under-cover">Check out my other designs and learn more about my website development work.</figcaption>
                        </a></figure>
                    </div>
                </section> */}

                {/* <section id="programmingFolio" className="boxed box-section tiled">
                    <BoxTitle text="Programming Work" />
                    <div className="box-body">
                        <figure>
                            <img src="" alt="put appropriate alt text here" className="cover" />
                            <p className="under-cover">Take a look at my programming portfolio.</p>
                        </figure>
                    </div>
                </section> */}
            </main>
        );
    }
}