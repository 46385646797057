import React from 'react';
import Slider from './SliderBase';

// REMOVED CONTENT // 
/*
    {
        file: "images/websiteExamples/cr1.png",
        alt: "A screenshot from CrystalsIdyll.com.",
        caption: "Wordpress Website - crystalsidyll.com",
        link: "https://crystalsidyll.com"
    },
    {
        file: "images/websiteExamples/cr2.png",
        alt: "A screenshot from CrystalsIdyll.com.",
        caption: "Wordpress Website - crystalsidyll.com",
        link: "https://crystalsidyll.com"
    }
*/

// CONTENT // 
const website = [
    // add other ones here 
    {
        file: "images/websiteExamples/pilar2.png",
        alt: "A screenshot from pilarlgarcia.com.",
        caption: "Custom Website for Pilar L. García",
        link: "https://pilarlgarcia.com"
    },
    {
        file: "images/websiteExamples/pilar1.png",
        alt: "A screenshot from pilarlgarcia.com.",
        caption: "Custom Website for Pilar L. García",
        link: "https://pilarlgarcia.com"
    },
    {
        file: "images/websiteExamples/taggartshire.png",
        alt: "A screenshot from taggartshirefarm.com",
        caption: "Custom Website for Taggartshire Farm",
        link: "https://taggartshirefarm.com",
    },
    {
        file: "images/websiteExamples/taggartshire2.png",
        alt: "A screenshot from taggartshirefarm.com",
        caption: "Custom Website for Taggartshire Farm",
        link: "https://taggartshirefarm.com",
    }
];

const WebsitesSlider = (props) => {
    return <Slider data={website} sliderName="websites" />
}

export default WebsitesSlider;